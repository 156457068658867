// COMPONENTS
import React from 'react'
import PostCard from 'BlogPost/PostCard'
import ContentContainer from 'layout/ContentContainer'

// List component for blog posts
const PostList = ({ posts, tagFilter }) => {
  return(
  <ContentContainer type='body'>
  { posts.map(post => (
    // Filters the posts based on tagFilter prop
    ((tagFilter !== 'all' &&
      post.node.frontmatter.tags.includes(tagFilter)) ||
      tagFilter === 'all') && 
      <PostCard content={post} key={post.node.frontmatter.path}/>
  ))}
  </ContentContainer>
  )
}

export default PostList
