// COMPONENTS
import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { ThemeContext } from 'layout/ThemeProvider'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
// NPM
import dateFormat from 'dateformat'

// Post card component for blog posts
const PostCard = ({ content }) => {
  const { invertedTheme } = useContext(ThemeContext)
  const { date, image, path, title, subtitle } = content.node.frontmatter
  return (
    <Container id='post-card'>
      <Row className='mb-2 rounded' style={{backgroundColor: 'rgba(0, 0, 0, 0.2)', height: '100%' }}>
        {/* TITLE, SUBTITLE, DATE */}
        <Col lg={8} sm={12}>
          <Row
            className='align-items-center'
            style={{ height: '75%' }}>
            <Col className='py-1' xs={12}>
              <Link to={path}>
                <h5>{title}</h5>
              </Link>
              <Link to={path}>
                <h6>{subtitle}</h6>
              </Link>
            </Col>
          </Row>

          <Row style={{ height: '25%' }}>
            <Col className='py-1' xs={12}>
              <p className={`text-small text-${invertedTheme}`}>
                {dateFormat(
                  date,
                  'mmm dd yyyy'
                )}
              </p>
            </Col>
          </Row>
        </Col>

        {/* IMAGE THUMBNAIL */}
        <Col lg={4} xs={12}>
          <Row className='align-items-center justify-content-center h-100'>
            <Col className='py-3'>
              <Link to={path}>
                <img
                  className='rounded'
                  src={image}
                  alt=''
                  style={{ width: '100%', height: '100%' }}
                />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default PostCard