// COMPONENTS
import React from 'react'
import { Link } from 'gatsby'
import ContentContainer from 'layout/ContentContainer'
import Badge from 'react-bootstrap/Badge'

const Tags = ({ tags, tagChange }) => {

  const setTag = tag => {
    tagChange(tag)
  }
  const { group } = tags

  // console.log({ urlTag, tags })

  return (
    <ContentContainer className='blogPostTags' type='secondary'>
      <Badge pill>
        <Link to={`?tag=all`} onClick={() => setTag('all')}>
          all posts
        </Link>
      </Badge>
      {group.map(tag => {
        return (
          <Badge
            pill
            // variant={themeMode === 'light' ? 'light' : 'primary'}
            // className={`text-${themeMode}`}
            key={tag.fieldValue}>
            <Link
              to={`?tag=${tag.fieldValue}`}
              onClick={() => setTag(tag.fieldValue)}>
              {`(${tag.totalCount}) ${tag.fieldValue}`}
            </Link>
          </Badge>
        )
      })}
    </ContentContainer>
  )
}

export default Tags
